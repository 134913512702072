 jQuery(function() { 
  jQuery('.swatch :radio').change(function() {
    var optionIndex = jQuery(this).closest('.swatch').attr('data-option-index');
    var optionValue = jQuery(this).val();
    jQuery(this)
      .closest('form')
      .find('.single-option-selector')
      .eq(optionIndex)
      .val(optionValue)
      .trigger('change');
    
    //alert(optionValue);
    jQuery('.swatch .header').each(function( i, val ) {
       //alert(jQuery(this).text());
      var prod_optionname=jQuery(this).text();
    jQuery("input[name='"+prod_optionname+"'][value='"+optionValue+"']").trigger('click');
    });

    
    //jQuery("input[name='{{ss_swatch}}'][value='"+optionValue+"']").trigger('click');
  	});
  	jQuery('.selector-wrapper').hide();
	jQuery('.product-form__item.selector-wrapper').hide();
    jQuery('.product-form__item.radio-wrapper').hide(); 
}); 